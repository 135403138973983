export enum IconReferences {
  "comb-long-haul-truck" = "./assets/icons/vehicles/comb-long-haul-truck.svg",
  "comb-long-haul-truck-fuel" = "./assets/icons/vehicles/comb-long-haul-truck-fuel.svg",
  "comb-short-haul-truck" = "./assets/icons/vehicles/comb-short-haul-truck.svg",
  "comb-short-haul-truck-fuel" = "./assets/icons/vehicles/comb-short-haul-truck-fuel.svg",
  "light-commercial-truck" = "./assets/icons/vehicles/light-commercial-truck.svg",
  "light-commercial-truck-fuel" = "./assets/icons/vehicles/light-commercial-truck-fuel.svg",
  "long-haul-truck" = "./assets/icons/vehicles/long-haul-truck.svg",
  "passenger-car" = "./assets/icons/vehicles/passenger-car.svg",
  "passenger-car-fuel" = "./assets/icons/vehicles/passenger-car-fuel.svg",
  "passenger-truck" = "./assets/icons/vehicles/passenger-truck.svg",
  "passenger-truck-fuel" = "./assets/icons/vehicles/passenger-truck-fuel.svg",
  "refuse-truck" = "./assets/icons/vehicles/refuse-truck.svg",
  "school-bus" = "./assets/icons/vehicles/school-bus.svg",
  "school-bus-fuel" = "./assets/icons/vehicles/school-bus-fuel.svg",
  "single-long-haul-truck" = "./assets/icons/vehicles/single-long-haul-truck.svg",
  "single-long-haul-truck-fuel" = "./assets/icons/vehicles/single-long-haul-truck-fuel.svg",
  "single-short-haul-truck" = "./assets/icons/vehicles/single-short-haul-truck.svg",
  "single-short-haul-truck-fuel" = "./assets/icons/vehicles/single-short-haul-truck-fuel.svg",
  "short-haul-truck" = "./assets/icons/vehicles/short-haul-truck.svg",
  "transit-bus" = "./assets/icons/vehicles/transit-bus.svg",
  "transit-bus-fuel" = "./assets/icons/vehicles/transit-bus-fuel.svg",

  "vehicleTypeIcon" = "./assets/icons/vehicle-type-icon.svg",
  "vehicleTypeIcon-fuel" = "./assets/icons/vehicle-type-icon-fuel.svg",

  "minusOperator" = "./assets/icons/calculations/minus-operator.svg",
  "opSaNumerator" = "./assets/icons/calculations/operational-savings-numerator.svg",
  "opSaDenominator" = "./assets/icons/calculations/operational-savings-denominator.svg",
  "emReNumerator" = "./assets/icons/calculations/emissions-reduction-numerator.svg",
  "emReDenominator" = "./assets/icons/calculations/emissions-reduction-denominator.svg",

  "addIcon" = "./assets/icons/add-icon.svg",
  "backIcon" = "./assets/icons/back-icon.svg",
  "businessReportIcon" = "./assets/icons/business-report-icon.svg",
  "chevronRightIcon" = "./assets/icons/chevron-right-icon.svg",
  "deleteIcon" = "./assets/icons/delete-icon.svg",
  "editIcon" = "./assets/icons/edit-icon.svg",
  "editIcon3" = "./assets/icons/edit3-icon.svg",
  "exportScenarioIcon" = "./assets/icons/export-scenario-icon.svg",
  "saveInputsIcon" = "./assets/icons/save-inputs-icon.svg",
  "loader" = "./assets/icons/loader.svg",
  "monthlyBillIcon" = "./assets/icons/monthly-bill-icon.svg",
  "plusIcon" = "./assets/icons/plus-icon.svg",
  "savingsIcon" = "./assets/icons/savings-icon.svg",
  "totalCostIcon" = "./assets/icons/total-cost-icon.svg",
  "upIcon" = "./assets/icons/up-icon.svg",
  "downIcon" = "./assets/icons/down-icon.svg",
  "uploadCsvIcon" = "./assets/icons/upload-csv-icon.svg",
  "businessGears" = "./assets/icons/business-gears.svg",
  "environElectricVehicle" = "./assets/icons/environ-electric-vehicle.svg",
  "environEvCharger" = "./assets/icons/environ-ev-charger.svg",
  "utilityPowerGeneration" = "./assets/icons/utility-power-generation.svg",
  "esaafcIcon" = "./assets/icons/esa-afc-icon.svg",
  "phoneIcon" = "./assets/icons/phone.svg",
  "doublePlug" = "./assets/icons/double-plug.svg",
  "speechBubbleInterrogationIcon" = "./assets/icons/speech-bubble-interrogation-icon.svg",
  "decreaseIcon" = "./assets/icons/decrease-icon.svg",
  "minusIcon" = "./assets/icons/minus-icon.svg",
  "vehicleAndPlugIcon" = "./assets/icons/vehicle-and-plug-icon.svg",
  "pumpIcon" = "./assets/icons/pump-icon.svg",
  "treeIcon" = "./assets/icons/tree-icon.svg",
  "mouseIcon" = "./assets/icons/mouse-icon.svg",
  "arrowIcon" = "./assets/icons/arrow-icon.svg",
  "singlePortIcon" = "./assets/icons/single-port-icon.svg",
  "dualPortIcon" = "./assets/icons/dual-port-icon.svg",
  "singlePort" = "./assets/icons/single-port-big-icon.svg",
  "dualPort" = "./assets/icons/dual-port-big-icon.svg",
  "options" = "./assets/icons/options-icon.svg",
  "mailIcon" = "./assets/icons/mail-icon.svg",
  "wheelchairIcon" = "./assets/icons/wheelchair-icon.svg",
  "xIcon" = "./assets/icons/x-icon.svg",
  "customerServicePrepayIcon" = "./assets/icons/customer-service-prepay-icon.svg",
  "disclaimerIcon" = "./assets/icons/disclaimer.svg",
  "arrowDownIcon" = "./assets/icons/arrow-down-icon.svg",
  "evVehicleIcon" = "./assets/icons/ev-vehicle-icon.svg",
  "iceVehicleIcon" = "./assets/icons/ice-vehicle-icon.svg",
  "copyScenario" = "./assets/icons/copy-icon.svg",
  "startOver" = "./assets/icons/start-over-icon.svg",
  "warn" = "./assets/icons/warn-icon.svg",
  "ownedCharger" = "./assets/icons/owned-charger-icon.svg",
  "charger-byod" = "./assets/icons/charger-byod-icon.svg",
  "subtract" = "./assets/icons/subtract.svg",
  "maintenance" = "./assets/icons/maintenance.svg",
  "plugCCS1" = "./assets/icons/charger-plug_CCS1.svg",
  "plugJ1772" = "./assets/icons/charger-plug_J1772.svg",
  "arrowRight2" = "./assets/icons/arrow-right-2-icon.svg",
  "collapsableIcon" = "./assets/icons/collapsable-icon.svg",
  "operationalSavings" = "./assets/icons/calculations/operational-savings.svg",
  "emissionsReduction" = "./assets/icons/calculations/emissions-reduction.svg",
  "arrowDown" = "./assets/icons/arrow-down.svg",
  "chevronDownIcon" = "./assets/icons/chevron-down-icon.svg",
  "chevronUpIcon" = "./assets/icons/chevron-up-icon.svg",
  "communityHouse" = "./assets/icons/community-house.svg",
  "businessBuilding" = "./assets/icons/business-building.svg",
  "noChargersQuery" = "./assets/icons/no-chargers-query.svg",
  "searchIcon" = "./assets/icons/search-icon.svg",
  "checkIcon" = "./assets/icons/check-icon.svg",
  "evCharger" = "./assets/icons/ev-charger.svg",
  "hamburgerIcon" = "./assets/icons/ui_hamburger.svg",
  "entergyChargerDefault" = "./assets/icons/entergy-charger-default.svg"
}
